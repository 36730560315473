import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form.js";
import {Alert, Button, Card, Col, Divider, Form, Input, Row, Select, Space, Spin, Tabs} from "antd";
import _ from "lodash";
import {Showable, Switch2} from "./Switcher.js";
import {callWithCatch, pp} from "./utils.js";
import MyWS from "./MyWS.js";
import {Bot} from "lucide-react";
import HtmlDiv from "./HtmlDiv.js";
import {useEffectOnce} from "react-use";
import User from "./User.js";
import Vimeo from '@u-wave/react-vimeo';
import Spacer from "./Spacer.js";
import {FileWordTwoTone} from "@ant-design/icons";

let inAdmin = location.pathname.includes("/admin");

let byTypes = {
    "כתב תביעה": ["התובע", "התובעת", "התובעות", "התובעים",],
    "כתב הגנה": ["הנתבע", "הנתבעת", "הנתבעות", "הנתבעים",],
    "בקשה": ["המבקש", "המבקשת", "המבקשות", "המבקשים",],
    "כתב תשובה": ["המשיב", "המשיבה", "המשיבות", "המשיבים",],
    "כתב עתירה": ["העותר", "העותרת", "העותרות", "העותרים",],
    "כתב ערעור": ["המערער", "המערערת", "המערערות", "המערערים",],
    "כתב ערער": ["העורר", "העוררת", "העוררות", "העוררים",],
}

let getByTypes = (type) => {
    return byTypes[type] || ["המשיב", "המשיבה", "המשיבות", "המשיבים",]
}

const Faq = () => {
    let examples = [
        ["מה בדיוק עושה המערכת החדשה?", "כותבת לך כתבי טענות חכמים, יסודיים ומותאם אישית לכל מקרה."],
        ["איך זה עובד?", "מזינים הרקע של המקרה במילים שלכם - המערכת לומדת ומנתחת כמויות אדירות של נתונים משפטיים, פסיקה וחוקים, ומייצרת כתבי טענות איכותיות ומותאמות אישית לכל מקרה."],
        ["כיצד המערכת יודעת להכין לי כתב טענות?", "המערכת מנתחת את הפרטים הספציפיים של כל מקרה, כגון העובדות, החוקים הרלוונטיים והפסיקה הקודמת, ומזהה את הטיעונים המשפטיים החזקים ביותר."],
        ["מה היתרון העיקרי?", "חיסכון בזמן, שיפור איכות הכתיבה, קבלת תובנות משפטיות יקרות ערך שיעזרו לך לפתח אסטרטגיה משפטית מוצלחת"],
    ]

    return <Card title='שאלות נפוצות על יצירת כתבי הטענות:'>
        {examples.map(r =>
            <div style={{marginBottom: "1rem"}} key={r[0]}>
                <div><b>{r[0]}</b></div>
                <div>{r[1]}</div>
                <div>{r[2]}</div>
            </div>)}
    </Card>
}

const Price = observer(({lines}) => {
    let clickBuy = () => {
        User.track('briefgen.buy')
        window.open("https://www.meshulam.co.il/purchase?b=2a9198096f9e045e05f08566217a3acc", '_blank')
    }

    return <div>
            <Card size="small" title="מחיר כתב טענות אחד">
            <div>
                <Space>
                    <h2 className="text-[24px] font-bold text-dark">
                        <span>49</span>
                    </h2>
                    <span className="text-base font-medium text-body-color">
                   ₪ + מע״מ
                </span>
                </Space>
                <div></div>
            </div>
            <Button onClick={clickBuy} type="primary">רכישה</Button>
        </Card>
    </div>
});

const BriefGenDL = ({bg}) => <Button icon={<FileWordTwoTone />} href={"/brief_gens/" + bg.id} variant="text">הורדה</Button>;

const BgView = observer(({bg}) => {
    if (!bg)
        return null;

    return <Card title={bg.name} extra={<BriefGenDL bg={bg}/>} size="small">
        <Tabs>
            <Tabs.TabPane tab="כתב הטענות" key="1">
                <div style={{fontSize: "14px", whiteSpace: "pre-line", width: "600px"}} className="">
                    <HtmlDiv html={bg.result}/>
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="רקע" key="2">
                <div style={{fontSize: "14px", whiteSpace: "pre-line", width: "600px"}}
                     className="">
                    {bg.background}
                </div>
            </Tabs.TabPane>
        </Tabs>
    </Card>
});

const AiStream = observer(({
    params, loading, setLoading
}) => {
    const [result, setResult] = useState(false);

    let gen = async () => {
        if (!params)
            return;

        setLoading(true);
        setResult(false);
        callWithCatch({
            url: `/searches/ai`,
            method: 'post',
            params: {brief_gen: true, ...params},
            onErr: () => {
                setLoading(false)
            },
            onSuccess: data => {
                let sub;
                if (data.text) {
                    setLoading(false);
                    setResult(data)
                }

                if (data.sub_to)
                    sub = MyWS(data.sub_to, r => {
                        setResult(r)
                        if (r.done) {
                            setLoading(false);
                            sub.unsubscribe();
                        }
                    })
            }
        })
    }

    useEffect(() => {
        gen()
    }, [params])

    if (!params)
        return null;

    let title = <Space><Bot/><Spin/></Space>;
    if (result?.done)
        title = <Space><Bot/>
            <div>סיימתי!</div>
        </Space>;

    if (result?.err)
        pp(result?.err)

    let spinText = s => {
        if (!s)
            return null;

        if (s.includes("@@"))
            return <span><span><HtmlDiv html={s.replace(/@@/, "")}/></span><span><Spin/></span></span>

        return <HtmlDiv html={s}/>
    }

    let dl = result?.done && <BriefGenDL bg={result}/>

    return <div className="w-full sm:flex-grow m-4">
        <Card size="small" title={title} extra={dl}>
            {result &&
                <div style={{fontSize: "14px", whiteSpace: "pre-line", width: "500px"}} className="">
                    {spinText(result.text)}
                    <br/>
                    {result.done &&
                        <div style={{fontSize: "10px", color: "#9CA3AF"}}>סיכום הAI בבטא ויכול לעשות טעויות. אנא ודאו את
                            תשובותיו עם הפסיקה.</div>}
                </div>}
        </Card>
        <br/>
        <br/>
    </div>

})


export default observer(() => {
    const [bg, setBg] = useState(null);
    const [bgs, setBgs] = useState(null);
    const [gen, setGen] = useState(false);

    useEffectOnce(() => {
        callWithCatch({
            url: "/brief_gens",
            method: 'get',
            params: {admin: inAdmin},
            onSuccess: data => {
                setBgs(data.brief_gens)
            },
        })
    })

    if (gen)
        return <Gen cancel={() => setGen(false)} />;

    let credits = gon.user.credits;
    let hasCredits = credits && credits > 0;

    let onPlay = () => {
        User.track('briefgen.play')
    }

    let bgCard = bg => {
        return <div key={bg.id}>
            <div className="text-gray-500" style={{fontSize: "12px"}}>
                <Space split={<Divider type="vertical"/>} gap={1}>
                    <div>{bg.created_at}</div>
                    { inAdmin && <div>{bg.user}</div> }
                </Space>
            </div>
            <div>
            <a onClick={() => setBg(bg)}>{bg.name}</a>
            </div>
        </div>
    }

    if (inAdmin)
        return <div className="flex flex-wrap">
            <div className="w-1/4">
                <div className="space-y-4">
                    {bgs && bgs.map(bg => bgCard(bg))}
                </div>
            </div>
            <div className="w-3/4">
                <BgView bg={bg}/>
            </div>
        </div>


    return <div className="flex flex-wrap">
        <div className="w-1/4">
            <div className="space-y-4">
                <Switch2 v={hasCredits}>
                    <div className="ml-8 space-y-4">
                        <div>יש לך {credits} כתבי טענות ליצור </div>
                        <Button type="primary" onClick={() => setGen(true)}>צור כתב טענות חדש</Button>
                    </div>
                    <div className="ml-8">
                        <Price lines={[]} />
                    </div>
                </Switch2>

                {bgs && bgs.map(bg => bgCard(bg))}
            </div>
        </div>
        <div className="w-3/4">
            { !bg && <div>
                <Faq  />
                <br/>
                <Card title="איך זה עובד:">
                    <Vimeo
                        // width="750px"
                        onPlay={onPlay}
                        responsive={true}
                        video="1012504957"
                    />
                </Card>
            </div>}
            <BgView bg={bg}/>
        </div>
    </div>
});

let Gen = observer(({cancel}) => {
    let [form] = useForm();
    const type = Form.useWatch('type', form);
    const [gen, setGen] = useState(false);
    const [loading, setLoading] = useState(false);

    let types = _.keys(byTypes);
    let supportedTypes = ["כתב תביעה", "בקשה"];

    if (gen)
        return <AiStream params={gen} loading={loading} setLoading={setLoading}/>

    let startGen = params => {
        setGen(params);
    }

    return <div>
            <Form form={form}
                  onFinish={startGen}
                  layout="vertical"
            >
                <Form.Item name="type" label="סוג כתב טענות" rules={[{required: true}]}>
                    <Select options={types.map(t => {
                        if (supportedTypes.includes(t))
                            return {label: t, value: t};
                        else {
                            let label = <div className="flex space-between">
                                <div className="flex-1">{t}</div>
                                <div className="flex-1 text-left">בקרוב!</div>
                            </div>
                            return {label, value: t, disabled: true}
                        }
                    })}
                            onChange={() => {
                                form.setFieldsValue({by: null});
                            }}
                            style={{width: "175px"}}
                    />
                </Form.Item>
                <Showable v={type}>
                    {type && <Form.Item name="pov" label="מוגש ע״י" rules={[{required: true}]}>
                        <Select options={getByTypes(type).map(t => ({label: t, value: t}))}
                                style={{width: "175px"}}
                        />
                    </Form.Item>}
                    <Form.Item name="subject" label="כותרת" rules={[{required: true}]} extra="לדוגמא: תביעה להחזר היטל השבחה ופיצוי מוסכם בהסכם מכר">
                        <Input style={{width: "450px"}} />
                    </Form.Item>
                    <Form.Item name="background" label="רקע" rules={[{required: true}]}  extra="כאן חשוב לכתוב בעיקר נתונים שהמערכת לא יכולה לדעת כגון: רקע עובדתי, השתלשלות העניינים, וכל פרט רלוונטי אחר.">
                        <Input.TextArea rows={10} style={{width: "450px"}}/>
                    </Form.Item>
                </Showable>
                <Showable v={type}>
                    <Alert type="warning" description="אחרי יצירת כתב הטענות לא ניתן לשנות את הרקע שהזנתם" style={{width: "450px"}} />
                    <br/>
                    <Space>
                            <Button type="primary" loading={loading} htmlType="submit">צור כתב טענות</Button>
                            <Button onClick={cancel}>ביטול</Button>
                    </Space>
                </Showable>

            </Form>
    </div>
})

